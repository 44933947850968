import {FalucheEnvironment} from '../app/model/faluche.environment';

export const environment: FalucheEnvironment = {
  production: true,
  env: 'production',
  useFirebaseEmulators: false,
  baseUrl: 'https://faluche.app',
  firebaseAppName: 'faluche',
  firebaseOptions: {
    apiKey: 'AIzaSyBP3A3Qdn2LOwg-QdiWpOdgectTxGrM9rg',
    projectId: 'paillardes-et-chants',
    messagingSenderId: '739077539428',
    appId: '1:739077539428:web:03d3bf1c9a82aa05c20171',
  },
  appTitle: 'Faluche.app',
  apiGraphqlEndpoint: 'https://api.faluche.app/bM63yqROBjW7RARe',
  apiRequestMaxResultsNumber: 0,
  apiRestEndpoint: 'https://api.faluche.app/api',
  facebookPageUrl: 'https://www.facebook.com/FalucheApp',
  facebookPageUrlShort: 'https://www.facebook.com/FalucheApp',
  mailContact: 'contact@faluche.app',
  facebookMessengerLink: 'https://m.me/faluche.app',
  instagramDirectMessageLink: 'https://ig.me/m/faluche.app',
  whatsAppLink: 'https://wa.me/33767694555',
  recaptchaV3SiteKey: '6LdbD5wUAAAAAP4hSjwJyC3pul1TgCcCuan4PyDw',
  facebookAppId: '928627278483768',
  facebookPageId: '109995008683158',
  googleMapsApiKey: 'AIzaSyDeaFPCyhKLVY5e-cIrgHFH7hUdRSVFZes',
  algoliaAppId: 'CHXO5K6W6F',
  algoliaApiKey: 'e746ef494c93ad3342870f664f190585',
  featureFlipping: {
    thirdPartyAuthentication: false,
    facebookChat: false,
  },
};
